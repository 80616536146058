import classNames from 'classnames';
import Image from 'next/image';
import { STATIC_FILE_URL } from '@/shared/constants';
import { useCallback, useRef, useState } from 'react';
import { useConfigProvider } from '@/lib/context';
import { PLATFORM_STEPS } from '@/shared/utils/homepage-util';
import { imageTransform } from '@/shared/utils/utils';
import './index.less';

export default function PlatformSteps() {
  const { isMobile } = useConfigProvider();
  const platformImageOneRef = useRef<HTMLDivElement>(null);
  const platformImageTwoRef = useRef<HTMLDivElement>(null);
  const platformImageThreeRef = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const goToStep = useCallback((step: number) => {
    const elem = document.getElementById('platform-steps-container');
    if (elem) {
      elem.scrollTo({ left: window.innerWidth * step });
    }
  }, []);
  const getImageSrc = useCallback(
    (index: number): string => {
      let src = '';
      switch (index) {
        case 1:
          src = isMobile
            ? `${STATIC_FILE_URL}/c044b91662fed7dedc60476ac2f2b8e9.png`
            : `${STATIC_FILE_URL}/b6db91d741c0111a369accbad71ae0a6.png`;
          break;
        case 2:
          src = isMobile
            ? `${STATIC_FILE_URL}/9e1389f3873a0976090f0233aaf732d5.png`
            : `${STATIC_FILE_URL}/094362a65c4596059fb6010b2cdf2d25.png`;
          break;
        case 3:
          src = isMobile
            ? `${STATIC_FILE_URL}/9cbade38b0181a8c3da2dd44c89adb9a.png`
            : `${STATIC_FILE_URL}/09ea2dd5e7546c4748d49f55190f115e.png`;
          break;
      }
      return imageTransform(src, isMobile ? 'grande' : '1024');
    },
    [isMobile],
  );
  const onHorizontalScroll = useCallback(() => {
    const threshold = 200;
    if ((platformImageThreeRef?.current?.getBoundingClientRect()?.left ?? 0) < threshold) {
      setTabIndex(2);
    } else if ((platformImageTwoRef?.current?.getBoundingClientRect()?.left ?? 0) < threshold) {
      setTabIndex(1);
    } else if ((platformImageOneRef?.current?.getBoundingClientRect()?.left ?? 0) < threshold) {
      setTabIndex(0);
    }
  }, []);

  return (
    <div className="platform-steps">
      <span className="title">Faster, easier, cheaper, than doing it yourself.</span>
      <div
        className="image-container"
        id="platform-steps-container"
        onScroll={onHorizontalScroll}
        onClick={() => {
          const step = tabIndex === 2 ? 0 : tabIndex + 1;
          setTabIndex(step);
          goToStep(step);
        }}
      >
        <div className="platform-image-1" ref={platformImageOneRef}>
          <Image fill src={getImageSrc(1)} alt="If you can dream it, you can source it on Pietra." />
        </div>
        <div className="platform-image-1" ref={platformImageTwoRef}>
          <Image fill src={getImageSrc(2)} alt="Pietra 3PL Services" />
        </div>
        <div className="platform-image-2" ref={platformImageThreeRef}>
          <Image fill src={getImageSrc(3)} alt="Pietra Fulfillment Services" />
        </div>
      </div>
      <div className="tabs">
        {PLATFORM_STEPS.map((step, index) => (
          <div
            className={classNames('tab', { show: !isMobile || tabIndex === index })}
            key={`tab-${index}`}
            onClick={() => {
              setTabIndex(index);
              goToStep(index);
            }}
          >
            <span
              className="text"
              style={{
                fontFamily: index <= tabIndex ? 'Labil-Bold' : 'Labil-Regular',
              }}
            >
              {step}
            </span>
            {!isMobile && <span className="bar" style={{ backgroundColor: index <= tabIndex ? '#000' : '#f2f1ee' }} />}
          </div>
        ))}
        {isMobile && tabIndex < PLATFORM_STEPS.length - 1 && <div className="bouncing-chevron">⮕</div>}
      </div>
      {isMobile && (
        <div className="bars">
          {PLATFORM_STEPS.map((item, index) => (
            <span
              className="bar"
              key={`bar-item-${index}`}
              style={{ backgroundColor: index <= tabIndex ? '#000' : '#f2f1ee' }}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
}
