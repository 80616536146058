import Image from 'next/image';
import classNames from 'classnames';
import { BrandVideoData } from '@/shared/types/landing-page';
import './index.less';
import { imgix } from '@/shared/utils/utils';

const BrandVideos = ({
  brandTitle,
  brandList,
  className,
  onVideoClick,
}: {
  brandList?: BrandVideoData[];
  brandTitle?: string;
  className?: string;
  onVideoClick?: (e: any) => void;
}) => {
  return (
    <div className={classNames('brand-video-wrap', className)}>
      <div className="title">{brandTitle || 'More than 250,000 brands & entrepreneurs have chosen Pietra'}</div>
      <div className="brand-list-wrapper">
        <div className="brand-list-h-left-gradient" />
        <div className="brand-list">
          {Array.from({ length: 2 }, (_) =>
            brandList?.map(({ logoWidth, logoHeight, logoSrc, logoAlt, videoPoster, videoSrc }, index: number) => (
              <div className="brand" key={index}>
                <div className="title">
                  <Image width={logoWidth} height={logoHeight} src={logoSrc} alt={logoAlt} />
                </div>
                <div className="slide">
                  <video
                    className="video"
                    preload="metadata"
                    onClick={onVideoClick}
                    autoPlay
                    muted
                    playsInline
                    loop
                    poster={imgix(videoPoster, { dpr: 2 })}
                    src={videoSrc}
                  />
                </div>
              </div>
            )),
          )}
        </div>
        <div className="brand-list-h-right-gradient" />
      </div>
    </div>
  );
};

export default BrandVideos;
