import { loginWithEmail, webToolsLink } from '@/lib/service';
import { FeaturedBrandsSliderItem } from '@/types/homepage';
import { encode } from 'js-base64';
import { CREATE_LOGIN_TOKEN, STATIC_FILE_URL } from '../constants';
import { isStaging } from './utils';
import storage from './storage';

export const SUPPLIER_INFORMATIONAL_SECTIONS = [
  {
    title: 'Set Up Your Profile',
    text: 'Give Creators a way to learn about your business and any unique production capabilities.',
    eventName: 'SET_UP_PROFILE',
  },
  {
    title: 'List Your Private Label Products',
    text: 'Create a catalog of product offerings to highlight manufacturing capabilities and even sell individual samples to Creators.',
    eventName: 'PRIVATE_LABEL_PRODUCTS',
  },
  {
    title: 'Accept Creator Manufacturing Requests',
    text: 'Work with Creators to make unique products and bring their ideas to life.',
    eventName: 'MANUFACTURING_REQUESTS',
  },
  {
    title: 'Connect With Creators In One Centralized Portal',
    text: "Chat with Creators from around the world directly through Pietra's Partner Portal. Manage order fulfillment, shipping, and payments through one easy-to-use hub.",
    eventName: 'MORE_CUSTOMERS_LESS_EFFORT',
  },
];

export const TARGET_USERS = [
  {
    title: 'Make it easier to scale your brand',
    image: `${STATIC_FILE_URL}/500ac1517bcce733da5bb3f60c0cfbe8.png`,
    buttonText: 'Start Now',
    buttonUrl: '/sell-on-pietra/register',
  },
  {
    title: 'Make it easier to start your brand',
    image: `${STATIC_FILE_URL}/3a4a48878ba6933a1b2de7be0cc2500c.png`,
    buttonText: 'Start Now',
    buttonUrl: '/sell-on-pietra/register',
  },
  {
    title: 'Make it easier to launch your merch',
    image: `${STATIC_FILE_URL}/844f22cb420b1834976adc77ecf1009b.png`,
    buttonText: 'Start Now',
    buttonUrl: '/sell-on-pietra/register',
  },
];

export const SUPPLIER_TARGET_USERS = [
  {
    title: "I'm a private label supplier.",
    text: 'I have unique private label products that first time business owners can take advantage of.',
    buttonText: 'Start Now',
    buttonUrl: 'https://partner.pietrastudio.com/login?create_account',
    eventName: 'PRIVATE_LABEL_SUPPLIER',
  },
  {
    title: "I'm a contract manufacturer.",
    text: 'I can help customers create products from scratch.',
    buttonText: 'Start Now',
    buttonUrl: 'https://partner.pietrastudio.com/login?create_account',
    eventName: 'CONTRACT_MANUFACTURER',
  },
  {
    title: "I'm looking to expand globally.",
    text: 'I want to find customers from around the world, not just in my region.',
    buttonText: 'Start Now',
    buttonUrl: 'https://partner.pietrastudio.com/login?create_account',
    eventName: 'EXPAND_GLOBALLY',
  },
];

export const HIGHLIGHTED_CREATORS = [
  {
    name: 'Helen & Whitney',
    category: 'Shop LINKD',
    image: `${STATIC_FILE_URL}/c8468fbc79d70e85944e50c1e3a48150.png`,
    hoverImage: `${STATIC_FILE_URL}/0d89002de1bdebe9c01a375acc8d6770.png`,
    link: 'https://pietra.store/s/helen-3',
  },
  {
    name: 'Liz Moody',
    category: 'Shop Healthier Together',
    image: `${STATIC_FILE_URL}/efe7133b755c99852172d5cbf6807253.png`,
    hoverImage: `${STATIC_FILE_URL}/708f4265f22d49b28d4d7fbd00fd3afa.png`,
    link: 'https://shop.lizmoody.com',
  },
  {
    name: 'Hailey Sani',
    category: 'Shop ILY Baby',
    image: `${STATIC_FILE_URL}/21abd4c15162aa3e35c7651eebaaadc5.png`,
    hoverImage: `${STATIC_FILE_URL}/8b3f070bbb3065687e75f38b50c5fec2.png`,
    link: 'https://shopilybaby.com',
  },
  {
    name: 'Jacqueline Fransway',
    category: 'Shop WANTED',
    image: `${STATIC_FILE_URL}/3c21717da30d67367b3eabec24caab30.png`,
    hoverImage: `${STATIC_FILE_URL}/f8bf74d757561f7ff576f5844169f5b3.png`,
    link: 'https://pietra.store/s/jacquelinefransway',
  },
  {
    name: 'Katie Morton',
    category: 'Shop OTT',
    image: `${STATIC_FILE_URL}/c55ab73baa04b7a57809f03ba92798f7.png`,
    hoverImage: `${STATIC_FILE_URL}/12ea3d203ba067d087eddeeb02d9bb73.png`,
    link: 'https://pietra.store/s/katiemorton',
  },
  {
    name: 'Aaron Anders',
    category: 'Shop Aaron Evy',
    image: `${STATIC_FILE_URL}/06adf2de9e86ea5b5eff9a2723481b90.png`,
    hoverImage: `${STATIC_FILE_URL}/7a31486cef00fb9b82216132f963a377.png`,
    link: 'https://aaronevy.com',
  },
  {
    name: 'Tiffany Daniels',
    category: 'Shop For Elizabeth Jewelry',
    image: `${STATIC_FILE_URL}/104fd9d994085afb466fc56bf0352e6e.png`,
    hoverImage: `${STATIC_FILE_URL}/2221bcd60414168c781e1b6fd10f7147.png`,
    link: 'https://pietra.store/s/for-elizabeth-jewelry',
  },
  {
    name: 'Kristina Schulman',
    category: 'Shop Set In Stone',
    image: `${STATIC_FILE_URL}/459ce92e56d393da09677f8b55715992.png`,
    hoverImage: `${STATIC_FILE_URL}/c0ce00d50f7ddc4348cc41d4ce2e098e.png`,
    link: 'https://pietra.store/s/kristinaschulman',
  },
  {
    name: 'Jourdan Sloane',
    category: 'Shop PRESENCE',
    image: `${STATIC_FILE_URL}/ad2ad0acc66ac4b2de88be7181c5df26.png`,
    hoverImage: `${STATIC_FILE_URL}/411b2e54bb9be0d17d4d6c45c2651971.png`,
    link: 'https://pietra.store/s/jourdan-sloane',
  },
  {
    name: 'Kira West',
    category: 'Shop Made by K.West',
    image: `${STATIC_FILE_URL}/e20ad978b1826da77a523a6922c78bb9.png`,
    hoverImage: `${STATIC_FILE_URL}/65d6084b4d594ff6d97cfb0fa4bd2f22.png`,
    link: 'https://madebykwest.com',
  },
  {
    name: 'One Small Blonde',
    category: 'Shop Evil Eye Collection',
    image: `${STATIC_FILE_URL}/1edb1f5263fa6872d54596bdd2fd98eb.png`,
    hoverImage: `${STATIC_FILE_URL}/b0e27c2a1d294ecd23d4f9e7096c426a.png`,
    link: 'https://pietra.store/s/one-small-blonde',
  },
];

export const PLATFORM_STEPS = ['A Vetted Factory Network', 'Low Cost Fulfillment Center', 'e-commerce & Tools to Grow'];

export const SUPPLIERS = [
  `${STATIC_FILE_URL}/0b6ecf25351f0b859403161d51bf4ccc.png`, // Ralph Lauren
  `${STATIC_FILE_URL}/0ca5e2d7b2cccdde90727e476924290c.png`, // KKW Beauty
  `${STATIC_FILE_URL}/5a6af18da2e19e62f7358fddfdc2f34d.png`, // Topshop
  `${STATIC_FILE_URL}/098a928e28dabded12971660cdc5bd2a.png`, // Pangaia
  `${STATIC_FILE_URL}/350a911f390b12079cf7aa8aa25580d9.png`, // Opening Ceremony
  `${STATIC_FILE_URL}/6d9d9d8edc304ebd9fb4afd83c19881a.png`, // SHEIN
  `${STATIC_FILE_URL}/20e8c08fc75e8e19945d691a85a287e0.png`, // Adidas
  `${STATIC_FILE_URL}/7d523cb22ef67a865f639edc9df49b6d.png`, // Theory
  `${STATIC_FILE_URL}/5482e1b6621e3cf5737469fd3d1f4e68.png`, // Urban Outfitters
];

export const CREATORS: FeaturedBrandsSliderItem[] = [
  {
    brand: 'Houseplant',
    logo: `${STATIC_FILE_URL}/68cbde612f81c94e960143d0a910feb1.svg`,
    name: 'Seth Rogen',
    image: `${STATIC_FILE_URL}/97f9921e9204a110e281b49f16a0dcd0.jpeg`,
    width: 26,
    height: 23,
  },
  {
    brand: 'Bad Mouth',
    logo: `${STATIC_FILE_URL}/dcaac88c8a8a3f96a6cc8dee9945b558.svg`,
    name: 'Kasey & Tiana',
    image: `${STATIC_FILE_URL}/63f237975ab2f27ca5354a0e57446be2.png`,
    width: 43,
    height: 16,
  },
  {
    brand: 'CBUM',
    logo: `${STATIC_FILE_URL}/c56967eb3f1fcd23708e02c8869e51cf.png`,
    name: 'Chris Bumstead',
    image: `${STATIC_FILE_URL}/94d13aa706cfeebf78fd4fa0ffc37cce.png`,
    width: 32,
    height: 12,
  },
  {
    brand: 'Arrae',
    logo: `${STATIC_FILE_URL}/ee6fff61b24128dd8d651446f377ba9e.svg`,
    name: 'Nish & Siff',
    image: `${STATIC_FILE_URL}/f132402bee6fff292beeae3558c0e29d.png`,
    width: 40,
    height: 12,
  },
  {
    brand: 'Curie',
    logo: `${STATIC_FILE_URL}/d868191959daf5af57541268b14a5a2f.svg`,
    name: 'Sarah',
    image: `${STATIC_FILE_URL}/4e47fc09d1370bf2453cc73f6de9f43a.png`,
    width: 55,
    height: 12,
  },
  {
    brand: 'Wellthy',
    logo: `${STATIC_FILE_URL}/dae61611637c9ac4aacda4250453f4d3.svg`,
    name: 'Mia',
    image: `${STATIC_FILE_URL}/5b18b1523659c999990736a604dc9849.png`,
    width: 47,
    height: 12,
  },
  {
    brand: 'Homecourt',
    logo: `${STATIC_FILE_URL}/e91335ae17a41094e692393bfcf84a35.svg`,
    name: 'Courteney Cox',
    image: `${STATIC_FILE_URL}/7cc3f44cad53cb4f28a48e25a1ca2612.png`,
    width: 60,
    height: 8,
  },
  {
    brand: 'Lively Ghosts',
    logo: `${STATIC_FILE_URL}/5e774cdfc48d5c67e922b2002778ace7.png`,
    name: 'Lindsay Kaye',
    image: `${STATIC_FILE_URL}/c57e19d5c4183c73d4f88411f5eab3f9.png`,
    width: 56,
    height: 7,
  },
];

export const SUPPLIER_STEPS = [
  {
    title: 'Work with influencers & creators.',
    text: "Set up a profile and start listing your private label and contract services. It's as easy as setting up a Facebook page.",
    cardTitle: 'Overview',
    color: 'white',
    imageUrl: `${STATIC_FILE_URL}/466e4771bd44d7059d58d64c9394ac8a.png`,
  },
  {
    title: 'Free lead generation for your business.',
    text: "Set up your profile and list products with no fees. That's right, it's absolutely free.",
    cardTitle: 'Lead Generation',
    color: 'tan',
    imageUrl: `${STATIC_FILE_URL}/d28ee5ccd456d0c8edf848e5357fb8b7.png`,
  },
  {
    title: 'Offer your customers a trusted co-packing and fulfillment partner.',
    text: 'Your customers will love you for making it easy for them to work with you to launch their brand.',
    cardTitle: 'Shipping & Logistics',
    color: 'pink',
    imageUrl: `${STATIC_FILE_URL}/4440f23bf1d9e6f0248a75c584631006.png`,
  },
  {
    title: 'Manage all your customers and projects in one place.',
    text: 'Invoice customers, print shipping labels, and make working with customers easier than ever.',
    cardTitle: 'Trusted Partner',
    color: 'green',
    imageUrl: `${STATIC_FILE_URL}/6bbdbe008036beb49e1272862eeb5f49.png`,
  },
];

export const OFFERINGS = [
  {
    title: 'Create',
    text: 'Work with vetted suppliers to source and create custom products and packaging.',
    desc: 'Access 700+ vetted suppliers across fashion, beauty, jewelry, food & beverage, and more.',
    imageUrl: `${STATIC_FILE_URL}/0b773c81a3789fd81a617a7b13053d19.png`,
  },
  {
    title: 'Manage',
    text: 'Supplier payments, order fulfillment, and co-packing with one tool.',
    desc: 'Access low-cost and reliable e-commerce services, like $1 order fulfillment for your website.',
    imageUrl: `${STATIC_FILE_URL}/3b85eb308cbbc3924f3049bb8d9a6216.png`,
  },
  {
    title: 'Grow',
    text: 'Connect your website and cross list products on retail and wholesale channels.',
    desc: 'Set up Instagram Shopping and sell on the Pietra Marketplace for effortless sales growth.',
    imageUrl: `${STATIC_FILE_URL}/b8fca654adc61ee5af3cad45888cc315.png`,
  },
];

export interface HomepageStoneItemType {
  color: string;
  subtitle1: string;
  subtitle2: string;
  creatorAvatar: string;
  stoneImage: string;
}

export const HOMEPAGE_PRODUCT_LINES = [
  'E-commerce Brand',
  'Streetwear Line',
  'Candle Line',
  'Makeup Line',
  'Athleisure Line',
  'Skincare Line',
  'Clothing Line',
  'Jewelry Line',
  'Fitness Line',
  'Coffee Line',
  'Merch Line',
  'Sneaker Line',
  'Sunglasses Line',
  'Swimwear Line',
  'Accessories Line',
  'Nail Polish Line',
  'Leather Goods Line',
  'Figurines Line',
  'Candy Line',
  'Fragrance Line',
  'Chocolate Line',
  'Tea Line',
  'Sauces Line',
];

export const STONE_PATHS: string[] = [
  'M483.94 37.9872C538.347 19.3595 614.618 5.34942 663.776 24.6184C712.934 43.8873 744.688 87.116 753.65 172.39C761.415 246.264 755.342 308.683 762.346 352.207C773.139 422.536 793.017 486.838 719.226 566.056C665.914 622.836 407.509 785.544 234.305 797.294C166.302 802.136 88.0652 797.449 69.2497 715.516C51.5858 639.916 71.4434 435.889 146.309 297.544C227.809 148.358 429.581 57.071 483.94 37.9872Z',
  'M787.234 468.823C749.018 593.931 527.352 759.709 335.716 787.99C212.76 808.601 78.607 767.821 30.9751 624.498C-57.0885 358.944 60.4484 164.848 161.251 77.6078C286.423 -30.723 469.75 -13.4666 572.214 55.079C733.387 162.451 840.404 295.781 787.234 468.823Z',
  'M42.9615 165.003C-26.0639 261.954 5.44777 345.946 19.4529 377.623C33.4581 409.3 62.9689 474.574 131.494 545.608C200.52 617.121 340.571 753.429 501.63 751.989C633.679 751.029 715.709 662.717 748.221 584.004C842.256 354.585 692.201 48.8538 495.628 6.61773C346.073 -25.5393 114.488 64.6924 42.9615 165.003Z',
  'M460.675 779.16C552.01 761.917 734.488 622.059 734.988 459.69C735.487 381.619 721.013 328.454 673.598 264.273C606.22 172.79 374.138 56.8811 288.293 18.5639C192.964 -23.585 96.1388 7.54769 43.7333 96.6351C-23.6453 210.15 -4.18035 374.435 41.7369 470.227C95.6398 582.305 100.926 603.379 229.194 685.282C357.962 768.143 369.34 796.402 460.675 779.16Z',
];

export const STONE_IMAGE_DATA: HomepageStoneItemType[] = [
  {
    color: '#E7BF59',
    subtitle1: 'Cbumfitness',
    subtitle2: 'by Chris Bumstead\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/9b02d0362bd6fa542583899e9741f0e1.png`,
    stoneImage: `${STATIC_FILE_URL}/5ae9d12d82680cbe2aa4eb66f09143dc.png`,
  },
  {
    color: '#ff5c3c',
    subtitle1: 'Inside Then Out',
    subtitle2: 'by Nya Jones\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/17ea8820e5f9ddb77907cee6c47e5a41.png`,
    stoneImage: `${STATIC_FILE_URL}/54279bbd265fe091e5609be37fac12c1.png`,
  },
  {
    color: '#406051',
    subtitle1: 'VEERT',
    subtitle2: 'by Julia Lang\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/e9e56fdb7db2c175a32b861429489922.png`,
    stoneImage: `${STATIC_FILE_URL}/0b3c98c27a575ff0d72d8690120e6dac.png`,
  },
  {
    color: '#E7BF59',
    subtitle1: 'Homecourt',
    subtitle2: 'by Courteney Cox\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/e417e1cf422d2922e5deaa38d468c4c4.png`,
    stoneImage: `${STATIC_FILE_URL}/b040365f9386ae4256c17f7f58889ca0.png`,
  },
  {
    color: '#F2AE9B',
    subtitle1: 'Poppi',
    subtitle2: 'by Allison and Stephen Ellsworth\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/72ba7ec5bc41c098687e7acafa4e8cf0.png`,
    stoneImage: `${STATIC_FILE_URL}/e8c8735fb2cbf3d460ccf60f6106c02a.png`,
  },
  {
    color: '#E7BF59',
    subtitle1: 'Cbumfitness',
    subtitle2: 'by Chris Bumstead\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/9b02d0362bd6fa542583899e9741f0e1.png`,
    stoneImage: `${STATIC_FILE_URL}/5ae9d12d82680cbe2aa4eb66f09143dc.png`,
  },
  {
    color: '#ff5c3c',
    subtitle1: 'Inside Then Out',
    subtitle2: 'by Nya Jones\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/17ea8820e5f9ddb77907cee6c47e5a41.png`,
    stoneImage: `${STATIC_FILE_URL}/54279bbd265fe091e5609be37fac12c1.png`,
  },
  {
    color: '#406051',
    subtitle1: 'VEERT',
    subtitle2: 'by Julia Lang\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/e9e56fdb7db2c175a32b861429489922.png`,
    stoneImage: `${STATIC_FILE_URL}/0b3c98c27a575ff0d72d8690120e6dac.png`,
  },
  {
    color: '#E7BF59',
    subtitle1: 'Homecourt',
    subtitle2: 'by Courteney Cox\nPietra Member',
    creatorAvatar: `${STATIC_FILE_URL}/e417e1cf422d2922e5deaa38d468c4c4.png`,
    stoneImage: `${STATIC_FILE_URL}/b040365f9386ae4256c17f7f58889ca0.png`,
  },
];

export const getPreviewAccountCredentials = (isStaging: boolean) => {
  if (isStaging) {
    return {
      username: 'previewpietra@pietrastudio.com',
      password: 'previewpietra',
    };
  } else {
    return {
      username: 'preview@pietrastudio.com',
      password: 'pietramerch',
    };
  }
};

export const goToCreatorHubPreview = async () => {
  try {
    const previewCredentials = getPreviewAccountCredentials(isStaging);
    const res = await loginWithEmail({
      ...previewCredentials,
      userType: 'STORE',
    });
    const token = res.token;
    storage.set(CREATE_LOGIN_TOKEN, token);
    window.location.href = `${webToolsLink()}?token=${encode(token)}`;
  } catch (error) {
    console.log(error);
  }
};
