import { CSSProperties } from 'react';
import { Affix } from 'antd';
import { useCreateAccountProvider } from '@/lib/context';
import './index.less';

export default function SignUp({
  style,
  signUpStyle,
  fontStyle,
}: {
  style?: CSSProperties;
  signUpStyle?: CSSProperties;
  fontStyle?: CSSProperties;
}) {
  const { showSignupDialog } = useCreateAccountProvider();
  return (
    <Affix offsetBottom={0} onChange={(e) => ({})} style={style}>
      <div className="sign-up" style={signUpStyle}>
        <div className="button" onClick={showSignupDialog}>
          <span className="title" style={fontStyle}>
            Dream big.
          </span>
          <br />
          <span className="title" style={fontStyle}>
            Start here.
          </span>
        </div>
      </div>
    </Affix>
  );
}
