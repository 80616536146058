import Image from 'next/image';
import React from 'react';
import { useConfigProvider } from '@/lib/context';
import cN from 'classnames';
import './index.less';

export default function Trustpilot({ className }: { className?: string }) {
  const { screenSize } = useConfigProvider();
  return (
    <div className={cN('trustpilot-wrap', className)}>
      <div className="logo">
        <div className="img">
          <Image fill src="/images/homepage-v2/hero-with-brand-video-bg/icon-star.svg" alt="Trustpilot Logo" />
        </div>
        <span>Trustpilot</span>
      </div>
      <div className="stars">
        {new Array(5).fill(0).map((_, index) => (
          <div key={index} className="star-wrap">
            <div className={cN('star', { 'half-star': index === 4 })}>
              <Image
                fill
                src={
                  index === 4
                    ? '/images/homepage-v2/hero-with-brand-video-bg/icon-half-star.svg'
                    : '/images/homepage-v2/hero-with-brand-video-bg/icon-star.svg'
                }
                alt="Star"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="rating">4.5 rating</div>
    </div>
  );
}
