import { TARGET_USERS } from '@/shared/utils/homepage-util';
import { imageTransform } from '@/shared/utils/utils';
import Image from 'next/image';
import { useCreateAccountProvider } from '@/lib/context';
import './index.less';

export default function TargetUsers() {
  const { showSignupDialog } = useCreateAccountProvider();
  return (
    <div className="target-user-wrapper">
      <span className="title">One powerful platform to execute on your vision.</span>
      <div className="users">
        {TARGET_USERS.map((item, index) => (
          <div className="user" key={`ways-to-use-pietra-${index}`} onClick={showSignupDialog}>
            <div className="top">
              <Image fill src={imageTransform(item.image, 'large')} alt={item.title} title={item.title} />
              <span className="title">{item.title}</span>
            </div>
            <span className="button">{item.buttonText}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
