import Image from 'next/image';
import classNames from 'classnames';
import { STATIC_FILE_URL } from '@/shared/constants';
import { EventSource } from '@/shared/constants/event';
import { imageTransform } from '@/shared/utils/utils';
import { useCallback, useState } from 'react';
import { useConfigProvider, useCreateAccountProvider } from '@/lib/context';
import { useRouter } from 'next/router';
import './index.less';

export default function Overview({ actionEventPage, steps }: { steps: any[]; actionEventPage: EventSource }) {
  const { isMobile } = useConfigProvider();
  const { showSignupDialog } = useCreateAccountProvider();
  const [activeTab, setActiveTab] = useState<number>(0);
  const router = useRouter();

  const logClickTab = useCallback((index: number) => {}, []);

  const renderContent = (step, index: number) => {
    if (!isMobile && index % 2 !== 0) {
      return (
        <>
          {step.text && (
            <div className="hp-positions_row-content">
              <span className="title">{step.title}</span>
              <span className="subtitle">{step.text}</span>
              {step.buttonText && (
                <span
                  className="button action-button black"
                  onClick={() => {
                    step.path ? router.push(step.path) : showSignupDialog();
                  }}
                >
                  {step.buttonText}
                </span>
              )}
            </div>
          )}
          {step.imageUrl && renderImage(step)}
        </>
      );
    }

    return (
      <>
        {step.imageUrl && renderImage(step)}
        {step.text && (
          <div className="hp-positions_row-content">
            <span className="title">{step.title}</span>
            <span className="subtitle">{step.text}</span>
            {step.buttonText && (
              <span
                className="button action-button black"
                onClick={() => {
                  step.path ? router.push(step.path) : showSignupDialog();
                }}
              >
                {step.buttonText}
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  const renderImage = (step) => {
    if (isMobile || !step?.textSurroundingImage) {
      return (
        <div className="hp-positions_row-img-box">
          <Image
            width={356}
            height={323}
            src={isMobile ? imageTransform(step.mobileImageUrl, 'grande') : imageTransform(step.imageUrl, '1024')}
            alt="card image"
            title="card image"
          />
        </div>
      );
    }

    return <div className="hp-positions_row-img-box with-text">{renderTextSurroundingImageContent(step)}</div>;
  };

  const renderTextSurroundingImageContent = (step) => {
    if (isMobile) {
      return (
        <Image
          width={356}
          height={323}
          src={imageTransform(step.mobileImageUrl, isMobile ? 'grande' : '1024')}
          alt="card image"
          title="card image"
        />
      );
    }

    const midPoint = Math.ceil(step.textSurroundingImage.length / 2);
    return step.textSurroundingImage.map((item, i: number) => {
      if (i === midPoint) {
        return (
          <>
            <Image
              key={`image-${i}`}
              width={356}
              height={323}
              src={imageTransform(step.imageUrl, isMobile ? 'grande' : '1024')}
              alt="card image"
              title="card image"
            />
            <div className="image-text-box" key={item.title}>
              <span className="image-text-box__title">
                <Image
                  src={`${STATIC_FILE_URL}/46a0624508320c000115abd29c0d55b8.svg`}
                  width={10}
                  height={10}
                  alt="Pietra Plus"
                />

                {item.title}
              </span>
              {item.lines.map((line, j: number) => (
                <span className="image-text-box__line" key={`line-${j}`}>
                  {line}
                </span>
              ))}
            </div>
          </>
        );
      }
      return (
        <div className="image-text-box" key={item.title}>
          <span className="image-text-box__title">
            <Image
              src={`${STATIC_FILE_URL}/46a0624508320c000115abd29c0d55b8.svg`}
              width={10}
              height={10}
              alt="Pietra Plus"
            />
            {item.title}
          </span>
          {item.lines.map((line, j: number) => (
            <span className="image-text-box__line" key={`line-${j}`}>
              {line}
            </span>
          ))}
        </div>
      );
    });
  };

  return (
    <div className="pietra-overview">
      <div className="title-container">
        <span className="title">A suite of tools to help your team scale</span>
        <div className="description">
          <span>
            Pietra&apos;s powerful platform allows you to start and scale your e-commerce business. Access Pietra&apos;s
            vetted factory network, creative services network, leverage our private 3PL and grow your sales with access
            to sales tools and services.
          </span>
        </div>
      </div>

      <div className="overview-container">
        <div className="hp-positions_content">
          {steps.map((step, index) => (
            <div
              key={`hp-positions-row-${index}`}
              className={classNames('hp-positions_row-wrapper', `slide-${index + 1}`, {
                open: activeTab >= index,
              })}
              onClick={() => {
                if (activeTab !== index) {
                  setActiveTab(index);
                  logClickTab(index);
                }
              }}
            >
              <div className={classNames('hp-positions_row', step.key)} style={{ backgroundColor: step.color }}>
                <div className="hp-positions_row-header">
                  <div className="hp-positions_row-header-text">0{index}</div>
                  <div className="hp-positions_row-header-text_vertical">
                    <span className="hp-positions_row-header-text position">{step.cardTitle}</span>
                  </div>
                </div>

                {step.overview && (
                  <div className="hp-positions_row-content" style={{ alignItems: 'center', maxWidth: 'unset' }}>
                    <span className={classNames('title', step.titleClass)}>{step.title}</span>
                    <div className="grid">
                      {(step.overview ?? []).map((card: any, j: number) => (
                        <div className="card" key={`card-item-${index}-${j}`}>
                          <div className="card-image-wrapper">
                            <Image
                              fill
                              src={imageTransform(card.imageUrl, isMobile ? 'large' : 'grande')}
                              alt="card image"
                              title="card image"
                            />
                          </div>
                          <div className="text">
                            <span>{card.text}</span>
                          </div>
                        </div>
                      ))}
                    </div>

                    {step.buttonText && (
                      <span
                        className="button action-button black"
                        onClick={() => {
                          step.path ? router.push(step.path) : showSignupDialog();
                        }}
                      >
                        {step.buttonText}
                      </span>
                    )}
                  </div>
                )}

                {renderContent(step, index)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
