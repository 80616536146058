import Image from 'next/image';
import { useConfigProvider, useCreateAccountProvider } from '@/lib/context';
import './index.less';

export default function Intro() {
  const { isMobile } = useConfigProvider();
  const { showSignupDialog } = useCreateAccountProvider();
  return (
    <div className="pietra-intro">
      <div className="img-warp">
        {isMobile ? (
          <Image
            width={480}
            height={254}
            src="https://static.pietrastudio.com/public_large/file_uploads/d16604f9eddebc6686d7e6d399728b46.png"
            alt="Pietra Creators"
            title="Pietra Creators"
            loading="eager"
          />
        ) : (
          <Image
            width={331}
            height={480}
            src="
            https://static.pietrastudio.com/public_large/file_uploads/bceaa337a90a96415c2681c7a046a9a1.png
          "
            alt="Pietra Creators"
            title="Pietra Creators"
            loading="eager"
          />
        )}
      </div>
      <div className="text">
        <div>
          <span className="title"> For the next generation of social savvy brand builders. </span>
          <span className="content">
            Pietra is a powerful platform that allows you to start and scale your e-commerce business. Use Pietra to
            source manufacturers, manage order fulfillment from Pietra&apos;s fulfillment center, and start selling your
            products online.
          </span>
          <span className="footer">We&apos;ve done the heavy lifting so you don&apos;t have to.</span>
          <span className="action-button utility-big" onClick={showSignupDialog}>
            Start for free
          </span>
        </div>
      </div>
      <div className="img-warp">
        {isMobile ? (
          <Image
            width={480}
            height={270}
            src="https://static.pietrastudio.com/public_large/file_uploads/9acaa31890e595cd90e502b9ede0da64.png"
            alt="Source products on Pietra"
            title="Source products on Pietra"
            loading="eager"
          />
        ) : (
          <Image
            width={250}
            height={480}
            src="https://static.pietrastudio.com/public_large/file_uploads/3143b6596149fc877b36d265fe8375f3.png"
            alt="Create your brand on Pietra"
            title="Create your brand on Pietra"
            loading="eager"
          />
        )}
      </div>
    </div>
  );
}
